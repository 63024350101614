<template>
  <div v-if="entity !== null && hasPerm('youth.view_seanceinscription')">
    <div
      class="header-line clickable"
      :style="getHeaderStyle(elt)"
      @click.prevent="showSeances"
    >
      <b-row>
        <b-col cols="10">
          <b>{{ elt.period.name }} {{ elt.schoolYear.name }}</b> -
          <b>{{ elt.seanceType.name }}</b> -
          <b>{{ elt.youthHome.name }}</b>
        </b-col>
        <b-col cols="2" class="text-right">
          <help-icon
            style="margin-right: 5px"
            help-text="Maintenez la touche Shift lors de la sélection d'une séance pour inscrire tous les enfants.">
          </help-icon>
          <span :class="!visible ? 'btn-primary' : 'btn-secondary'" class="btn btn-sm">
            {{ elt.inscriptionsCount }}
          </span>
        </b-col>
      </b-row>
    </div>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-show="visible">
      <div v-if="hasLimits" class="limit-indicator-info">
        Les limites n'incluent pas les inscriptions ci-dessous
      </div>
      <div v-if="!isLoading(loadingName)">
        <div v-if="confirmationWarning" class="confirmation-warning">
          <b-row>
            <b-col cols="9">
              <i class="fa fa-warning"></i>
              Des séances sont en attente de confirmation
            </b-col>
            <b-col cols="3" class="text-right">
              <router-link
                :to="{ name: 'confirm-inscriptions', }" class="btn btn-secondary"
                v-if="hasPerm('portal')"
              >
                Accéder
              </router-link>
            </b-col>
          </b-row>
        </div>
        <div v-if="seances.length">
          <div v-if="showGrid">
            <div v-if="onlyIndividuals.length > 0 && allIndividuals.length === 0">
              <i>Aucune séance pour {{ filteredIndividualsNames }}</i>
            </div>
            <table class="table grid" v-else-if="getFilteredDays().length">
              <tr>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th
                  v-for="elt in allSeanceCodes"
                  :key="elt.code"
                  :style="'background: ' + getCodeColor(elt.code)"
                  :title="elt.code"
                  class="seance-code grid-col ut-seance-code"
                >
                  {{ elt.label }}
                </th>
              </tr>
              <tr class="tr-header" v-if="hasPerm('youth.add_seanceinscription')">
                <th colspan="2"><i>Tout cocher</i></th>
                <th
                  v-for="elt in allSeanceCodes"
                  :key="elt.code"
                  title="Tout cocher"
                  class="grid-col"
                >
                  <div class="checkbox-holder">
                    <input
                      type="checkbox"
                      :checked="isAllChecked(elt.code)"
                      @click="checkAll(elt.code)"
                      class="inline"
                      v-if="canChange"
                    /><span></span>
                  </div>
                </th>
              </tr>
              <tr
                v-for="(gridLine, index) in getGridLines()"
                :key="gridLine.key"
                :class="(index % allIndividuals.length) === 0 ? 'line-day' : ''"
              >
                <td
                  :rowspan="allIndividuals.length"
                  v-if="(index % allIndividuals.length) === 0"
                >
                  <span :title="weekNum(gridLine.day)">
                    {{ gridLine.day | dateToString('dddd LL') }}
                  </span>
                </td>
                <td>
                  {{ gridLine.individual.firstName }}
                </td>
                <td
                  v-for="elt in allSeanceCodes"
                  :key="elt.code"
                  :style="'background: ' + getCodeColor(elt.code)"
                  :title="getInscriptionTooltip2(gridLine, elt)"
                  class="grid-col"
                >
                  <div
                    v-if="isNotConfirmed2(gridLine.day, elt.code, gridLine.individual)"
                    class="badge badge-warning"
                  >
                    À confirmer
                  </div>
                  <div
                    v-else-if="isRefused2(gridLine.day, elt.code, gridLine.individual)"
                    class="badge badge-danger"
                  >
                    Refusée
                  </div>
                  <div
                    v-else-if="isWaiting2(gridLine.day, elt.code, gridLine.individual)"
                    class="badge badge-warning"
                  >
                    Liste d'attente
                  </div>
                  <div class="multiple-holder" v-if="getSeanceAllowMultiplier(gridLine.day, elt.code)">
                    <number-input
                      v-if="hasSeance(gridLine.day, elt.code, gridLine.individual)"
                      :value="getIndividualMultiplier2(gridLine.day, elt.code, gridLine.individual)"
                      @change="setIndividualMultiplier2($event, gridLine.day, elt.code, gridLine.individual)"
                      :input-style="numberStyle"
                      :arrows="true"
                    ></number-input>
                    <div
                      v-if="isSeanceBlocked(gridLine.day, elt.code, gridLine.individual)"
                      title="Inscription bloquée par limite"
                      v-b-tooltip
                    >
                      <i class="fas fa-ban"></i>
                    </div>
                  </div>
                  <div class="checkbox-holder" v-else>
                    <input
                      type="checkbox"
                      v-if="hasSeance(gridLine.day, elt.code, gridLine.individual)"
                      :checked="doesIndividualHaveInscription2(gridLine.day, elt.code, gridLine.individual)"
                      @click="setIndividualInscription2($event, gridLine.day, elt.code, gridLine.individual)"
                      :disabled="!canChange || !isInscriptionPossible2(gridLine.day, elt.code, gridLine.individual)"
                      class="inline"
                    />
                    <div
                      v-if="isSeanceBlocked(gridLine.day, elt.code, gridLine.individual)"
                      title="Inscription bloquée par limite"
                      v-b-tooltip
                    >
                      <i class="fas fa-ban"></i>
                    </div>
                    <span></span>
                  </div>
                  <div>
                    <seance-limits-loader
                      :individual="gridLine.individual"
                      :seance="getSeanceFromCode(gridLine.day, elt.code)"
                      v-if="isIndividualHaveNewInscription2(gridLine.day, elt.code, gridLine.individual)"
                      @limits-loaded="onLimitsLoaded"
                    >
                    </seance-limits-loader>
                  </div>
                  <workshop-select
                    :seance="getSeanceFromCode(gridLine.day, elt.code)"
                    :individual="gridLine.individual"
                    :disabled="!canChange || !isInscriptionPossible2(gridLine.day, elt.code, gridLine.individual)"
                    v-if="doesIndividualHaveInscription2(gridLine.day, elt.code, gridLine.individual)"
                  >
                  </workshop-select>
                </td>
              </tr>
            </table>
            <div v-else>
              <i>Aucune séance ne correspond au filtre sélectionné</i>
            </div>
          </div>
          <div v-else>
            <div v-for="seancesGroup in getOrderedSeancesGroups()" :key="seancesGroup">
              <div v-if="seancesGroup !== 'all'" class="group-header">
                {{ seancesGroup | dateToString('dddd LL') }}
              </div>
              <div v-if="onlyIndividuals.length > 0 && allIndividuals.length === 0">
                <i>Aucune séance pour {{ filteredIndividualsNames }}</i>
              </div>
              <div v-else-if="getFilteredSeances(seancesGroup).length === 0">
                  <i>Aucune séance ne correspond au filtre sélectionné</i>
              </div>
              <div
                v-for="seance in getFilteredSeances(seancesGroup)"
                 v-bind:key="seance.id"
                 class="field-line ut-seance"
              >
                <b-row class="field-line" :style="'background: ' + getSeanceColor(seance)">
                  <b-col>
                    <div :title="seance.code">
                      <span v-if="seancesGroup === 'all'">{{ seance.name }}</span>
                      <span v-else :title="weekNum(seance.date)">{{ seance.getBaseName() }}</span>
                      &nbsp;
                      <div class="small-badge badge-light">{{ seance.getCodeName() }}</div>
                      <div class="seance-comments" v-if="seance.comments">{{ seance.comments }}</div>
                    </div>
                  </b-col>
                  <b-col class="text-right">
                    <div
                      v-for="individual in getSeanceIndividuals(seance)"
                      :key="individual.id"
                      :title="getForbiddenReason(seance, individual)"
                    >
                      <div
                        v-if="isNotConfirmed(seance, individual)"
                        class="badge badge-warning"
                      >
                        À confirmer
                      </div>
                      <div
                        v-else-if="isRefused(seance, individual)"
                        class="badge badge-danger"
                      >
                        Refusée
                      </div>
                      <div
                        v-else-if="isWaiting(seance, individual)"
                        class="badge badge-warning"
                      >
                        Liste d'attente
                      </div>
                      {{ individual.firstName }}
                      <div class="checkbox-holder">
                        <input
                          type="checkbox"
                          :checked="doesIndividualHaveInscription(seance, individual)"
                          @click="setIndividualInscription3($event, seance, individual)"
                          :disabled="!canChange || !isInscriptionPossible(seance, individual)"
                          class="inline"
                          v-b-tooltip="getForbiddenReason(seance, individual)"
                        /><span></span>
                      </div>
                      <div>
                        <seance-limits-loader
                          :seance="seance"
                          :individual="individual"
                          v-if="isIndividualHaveNewInscription(seance, individual)"
                          @limits-loaded="onLimitsLoaded"
                        >
                        </seance-limits-loader>
                      </div>
                      <workshop-select
                        :seance="seance"
                        :individual="individual"
                        v-if="doesIndividualHaveInscription(seance, individual)"
                      >
                      </workshop-select>
                    </div>
                    <div
                      v-for="individual in getBlockedIndividuals(seance)"
                      :key="individual.id"
                    >
                      {{ individual.firstName }}
                      <div
                        class="checkbox-holder"
                        title="Inscription bloquée par limite"
                        v-b-tooltip
                      >
                        <i class="fa fa-ban"></i>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapMutations, mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import HelpIcon from '@/components/Controls/HelpIcon'
import WorkshopSelect from '@/components/Seances/WorkshopSelect'
import SeanceLimitsLoader from '@/components/SeanceLimits/SeanceLimitsLoader.vue'
import { BackendMixin } from '@/mixins/backend'
import { makeInscriptionRule, makeEntitySeance, getPeriodHeaderStyle } from '@/types/youth'
import { BackendApi } from '@/utils/http'
import { existsIn, distinct } from '@/utils/arrays'
import { dateToString } from '@/filters/texts'
import NumberInput from '@/components/Controls/NumberInput.vue'
import { filterDay, filterSeance } from '@/utils/youth'

export default {
  name: 'entity-seances-list',
  components: {
    NumberInput,
    WorkshopSelect,
    SeanceLimitsLoader,
    HelpIcon,
    LoadingGif,
  },
  mixins: [BackendMixin],
  props: {
    entity: Object,
    elt: Object,
    seancesFilter: Object,
    includeCancelledActivities: Boolean,
    onlyIndividuals: Array,
    onlyDay: {
      type: String,
      default: '',
    },
    autoload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      visible: false,
      loadingName: '',
      seances: [],
      seancesGroups: new Map(),
      rules: [],
      inscriptionPossibilitiesMap: new Map(),
      groupColors: new Map(),
      allSeanceCodes: [],
      theIndividuals: [],
      seancesFromCode: new Map(),
      confirmationWarning: false,
      inscriptionsStatus: new Map(),
      hasLimits: false,
    }
  },
  computed: {
    numberStyle() {
      return {
        fontSize: '11px',
        padding: '4px',
        textAlign: 'right',
      }
    },
    mainIndividuals() {
      let mainIndividuals = this.entity.getMainIndividuals()
      if (mainIndividuals.length === 0) {
        mainIndividuals = this.onlyIndividuals
      }
      return mainIndividuals
    },
    allIndividuals() {
      if (this.onlyIndividuals.length) {
        return this.theIndividuals.filter(elt => existsIn([elt.id], this.onlyIndividuals.map(elt => elt.id)))
      }
      return this.theIndividuals
    },
    filteredIndividualsNames() {
      return this.onlyIndividuals.map(elt => elt.firstName).join(', ')
    },
    showGrid() {
      if (this.elt.youthHome.overrideShowByDays) {
        return this.elt.youthHome.showByDays
      } else {
        return this.elt.seanceType.showByDays
      }
    },
    allowMultiplier() {
      return this.elt.youthHome.allowMultiplier
    },
    canChange() {
      return this.hasPerm('youth.add_seanceinscription')
    },
  },
  created() {
    this.loadingName = 'entity-seances-' + this.elt.index
    this.init()
  },
  watch: {
    onlyIndividuals: function() {
      this.init()
    },
    allowMultiplier: function() {},
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading', 'setEditMode']),
    init() {
      if (this.autoload) {
        this.showSeances()
      }
    },
    async showSeances() {
      if (!this.loaded) {
        this.loaded = true
        this.startLoading(this.loadingName)
        await this.loadSeances()
        await this.loadInscriptionRules()
        await this.loadSeancesStatus()
        this.endLoading(this.loadingName)
      }
      this.visible = !this.visible
    },
    async loadSeances() {
      if (this.entity && this.entity.id > 0) {
        const youthHomeId = this.elt.youthHome.id
        const typeId = this.elt.seanceType.id
        const periodId = this.elt.period.id
        const entityId = this.entity.id
        let url = '/api/youth/entity-seances/' + entityId + '/' + youthHomeId + '/' + typeId + '/' + periodId + '/'
        if (this.includeCancelledActivities) {
          url += '?include_cancelled_activities=1'
        }
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          let seances = resp.data.map(elt => makeEntitySeance(elt))
          if (this.showGrid) {
            for (let seance of seances) {
              if (!this.seancesGroups.has(seance.date)) {
                this.seancesGroups.set(seance.date, [])
              }
              let groupSeances = this.seancesGroups.get(seance.date)
              groupSeances.push(seance)
            }
          } else {
            this.seancesGroups.set('all', seances)
          }
          this.seances = seances
          this.$emit('seances-loaded', this.seances)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    isRefused(seance, individual) {
      const key = '' + individual.id + ':' + seance.id
      if (this.inscriptionsStatus.has(key)) {
        const ins = this.inscriptionsStatus.get(key)
        return (!ins.cancelled) && ins.refused && (!ins.waiting)
      }
      return false
    },
    isWaiting(seance, individual) {
      const key = '' + individual.id + ':' + seance.id
      if (this.inscriptionsStatus.has(key)) {
        const ins = this.inscriptionsStatus.get(key)
        return (!ins.cancelled) && ins.refused && ins.waiting
      }
      return false
    },
    isNotConfirmed(seance, individual) {
      const key = '' + individual.id + ':' + seance.id
      if (this.inscriptionsStatus.has(key)) {
        return !this.inscriptionsStatus.get(key).confirmed
      }
      return false
    },
    isRefused2(day, code, individual) {
      let seance = this.getSeanceFromCode(day, code)
      if (seance) {
        return this.isRefused(seance, individual)
      }
      return false
    },
    isWaiting2(day, code, individual) {
      let seance = this.getSeanceFromCode(day, code)
      if (seance) {
        return this.isWaiting(seance, individual)
      }
      return false
    },
    isNotConfirmed2(day, code, individual) {
      let seance = this.getSeanceFromCode(day, code)
      if (seance) {
        return this.isNotConfirmed(seance, individual)
      }
      return false
    },
    async loadSeancesStatus() {
      if (this.entity && this.entity.id > 0) {
        const inscriptionsStatus = new Map()
        this.confirmationWarning = false
        let url = '/api/youth/entity-inscriptions-status/' + this.entity.id + '/'
        url += this.elt.youthHome.id + '/'
        url += this.elt.seanceType.id + '/'
        url += this.elt.period.id + '/'
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          const inscriptions = resp.data
          for (const ins of inscriptions) {
            const key = '' + ins.individual + ':' + ins.seance
            inscriptionsStatus.set(key, ins)
          }
          this.inscriptionsStatus = inscriptionsStatus
          this.confirmationWarning = inscriptions.filter(elt => !elt.confirmed).length > 0
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async loadInscriptionRules() {
      if (this.entity && this.entity.id > 0) {
        let url = '/api/youth/inscription-rules/' + this.elt.seanceType.id + '/' + this.elt.youthHome.id + '/'
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          this.rules = resp.data.map(elt => makeInscriptionRule(elt))
          this.initializeRules()
          this.initializeRulesColors()
          this.initializeSeanceCodes()
          this.theIndividuals = this.getAllIndividuals()
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    getSeanceIndividuals(seance) {
      let individuals = []
      for (let individual of this.mainIndividuals) {
        if (seance.availableForIndividualIds.indexOf(individual.id) >= 0) {
          if (
            (this.onlyIndividuals.length === 0) ||
            (existsIn([individual.id], this.onlyIndividuals.map(elt => elt.id)))
          ) {
            individuals.push(individual)
          }
        }
      }
      return individuals
    },
    getBlockedIndividuals(seance) {
      let individuals = []
      for (let individual of this.mainIndividuals) {
        if (seance.isBlockedForSeance(individual.id)) {
          individuals.push(individual)
        }
      }
      return individuals
    },
    getSeanceFromCode(day, code) {
      let key = '' + day + '-' + code
      let seance = this.seancesFromCode.get(key) || null
      if (seance) {
        // La séance ne correspond pas au filtre et doit donc être cachée
        if (!this.filterSeance(seance)) {
          return null
        }
      }
      return seance
    },
    hasSeance(day, code, individual) {
      const seance = this.getSeanceFromCode(day, code)
      if (seance) {
        return seance.isAvailableForIndividual(individual.id)
      }
      return false
    },
    isSeanceBlocked(day, code, individual) {
      const seance = this.getSeanceFromCode(day, code)
      if (seance) {
        return seance.isBlockedForSeance(individual.id)
      }
      return false
    },
    getSeanceAllowMultiplier(day, code) {
      let key = '' + day + '-' + code
      let seance = this.seancesFromCode.get(key) || null
      if (seance) {
        return seance.youthHome.allowMultiplier
      }
      return false
    },
    getInscriptionTooltip2(gridLine, elt) {
      let text = this.getForbiddenReason2(gridLine.day, elt.code, gridLine.individual)
      if (!text) {
        text = this.getSeanceLabelFromCode(gridLine.day, elt)
      }
      return text
    },
    getSeanceLabelFromCode(day, elt) {
      let key = '' + day + '-' + elt.code
      let label = ''
      let seance = this.seancesFromCode.get(key) || null
      if (seance) {
        label = seance.name
        if (seance.comments) {
          label += ' - ' + seance.comments
        }
      }
      return label
    },
    doesIndividualHaveInscription(seance, individual) {
      return seance.doesIndividualHaveInscription(individual.id)
    },
    doesIndividualHaveInscription2(day, code, individual) {
      let seance = this.getSeanceFromCode(day, code)
      if (seance) {
        return seance.doesIndividualHaveInscription(individual.id)
      }
      return false
    },
    getIndividualMultiplier2(day, code, individual) {
      let seance = this.getSeanceFromCode(day, code)
      if (seance) {
        return seance.getIndividualNewMultiplier(individual.id)
      }
      return 0
    },
    setIndividualMultiplier2(event, day, code, individual) {
      let currentValue = this.getIndividualMultiplier2(day, code, individual)
      let seance = this.getSeanceFromCode(day, code)
      const newValue = +event
      if (seance && (newValue !== currentValue)) {
        const inscription = seance.setIndividualMultiplier(individual.id, newValue)
        this.notifyInscriptionChanged(seance, individual, inscription)
      }
    },
    isIndividualHaveNewInscription(seance, individual) {
      return seance.isIndividualInscriptionNew(individual.id)
    },
    isIndividualHaveNewInscription2(day, code, individual) {
      let seance = this.getSeanceFromCode(day, code)
      if (seance) {
        return seance.isIndividualInscriptionNew(individual.id)
      }
      return false
    },
    notifyInscriptionChanged(seance, individual, inscription) {
      this.$emit(
        'inscription-changed',
        {
          seance: seance,
          individual: individual,
          inscription: inscription,
        }
      )
    },
    setIndividualInscription(seance, individual) {
      let inscription = seance.toggleIndividualInscription(individual.id)
      this.notifyInscriptionChanged(seance, individual, inscription)
      if (inscription) {
        // si inscription : on décoche des séances qui ne peuvent pas correspondre
        let linkedSeances = this.getLinkedSeances(seance)
        let linkedChildren = []
        for (let linkedSeance of linkedSeances) {
          // désinscrire pour cette séance
          const parent = this.getParentSeance(linkedSeance)
          if (parent) {
            parent.resetIndividualInscription(individual.id)
            this.notifyInscriptionChanged(parent, individual, false)
            linkedChildren = linkedChildren.concat(this.getChildrenSeances(parent))
          } else {
            linkedSeance.resetIndividualInscription(individual.id)
            this.notifyInscriptionChanged(linkedSeance, individual, false)
            linkedChildren = linkedChildren.concat(this.getChildrenSeances(linkedSeance))
          }
        }
        for (let linkedChild of linkedChildren) {
          // désinscrire pour cette séance
          linkedChild.resetIndividualInscription(individual.id)
          this.notifyInscriptionChanged(linkedChild, individual, false)
        }
      }
      // modifie les séances "filles" (exemple journées suivantes d'un camp)
      const childrenSeances = this.getChildrenSeances(seance)
      let childLinked = []
      for (const childSeance of childrenSeances) {
        if (inscription) {
          childSeance.setIndividualInscription(individual.id)
          childLinked = childLinked.concat(this.getLinkedSeances(childSeance))
        } else {
          childSeance.resetIndividualInscription(individual.id)
        }
        this.notifyInscriptionChanged(childSeance, individual, inscription)
      }
      for (let linkedSeance of childLinked) {
        // désinscrire pour cette séance
        linkedSeance.resetIndividualInscription(individual.id)
        this.notifyInscriptionChanged(linkedSeance, individual, false)
      }
      // modifie les séances qui en dépendent (exemple forfait)
      const dependencySeances = this.getDependencySeances(seance)
      let allSecondarySeances = this.getSecondarySeances(seance)
      for (const dependencySeance of dependencySeances) {
        allSecondarySeances = allSecondarySeances.concat(this.getSecondarySeances(dependencySeance))
        if (inscription) {
          dependencySeance.setIndividualInscription(individual.id)
          const dependencyLinkedSeances = this.getLinkedSeances(dependencySeance)
          for (let dependencyLinkedSeance of dependencyLinkedSeances) {
            // désinscrire pour cette séance
            dependencyLinkedSeance.resetIndividualInscription(individual.id)
            this.notifyInscriptionChanged(dependencyLinkedSeance, individual, false)
          }
        } else {
          dependencySeance.resetIndividualInscription(individual.id)
        }
        this.notifyInscriptionChanged(dependencySeance, individual, inscription)
      }
      for (let secondarySeance of allSecondarySeances) {
        if (inscription) {
          this.markInscriptionPossible(secondarySeance, individual, true)
        } else {
          // désinscrire pour cette séance
          secondarySeance.resetIndividualInscription(individual.id)
          this.notifyInscriptionChanged(secondarySeance, individual, false)
          this.markInscriptionPossible(secondarySeance, individual, false)
        }
      }
      return inscription
    },
    forceInscription(seance, individual, value) {
      let inscription = seance.doesIndividualHaveInscription(individual.id)
      if (inscription !== value) {
        this.setIndividualInscription(seance, individual)
      }
    },
    setIndividualInscription2(event, day, code, individual) {
      let seance = this.getSeanceFromCode(day, code)
      // changer l'inscription du membre cliqué
      let inscription = this.setIndividualInscription(seance, individual)
      if (event.shiftKey) {
        // Si la touche Shift est enfoncée : les autres membres de la famille
        // auront la même inscription
        for (let sibling of this.allIndividuals) {
          if (sibling.id !== individual.id) {
            this.forceInscription(seance, sibling, inscription)
          }
        }
      }
    },
    setIndividualInscription3(event, seance, individual) {
      // changer l'inscription du membre cliqué
      let inscription = this.setIndividualInscription(seance, individual)
      if (event.shiftKey) {
        // Si la touche Shift est enfoncée : les autres membres de la famille
        // auront la même inscription
        for (let sibling of this.allIndividuals) {
          if (sibling.id !== individual.id) {
            this.forceInscription(seance, sibling, inscription)
          }
        }
      }
    },
    filterDate(day) {
      if (this.onlyDay) {
        if (moment(day).format('YYYY-MM-DD') !== this.onlyDay) {
          return false
        }
      }
      return filterDay(this.seancesFilter, day)
    },
    filterSeance(seance) {
      return filterSeance(this.seancesFilter, seance)
    },
    getFilteredSeances(seanceGroup) {
      if (this.allIndividuals.length) {
        let seances = this.seancesGroups.get(seanceGroup)
        return seances.filter(
          seance => {
            return this.filterDate(seance.date) && this.filterSeance(seance)
          }
        )
      } else {
        return []
      }
    },
    getFilteredDays() {
      let days = this.getOrderedSeancesGroups()
      return days.filter(
        day => {
          return this.filterDate(day)
        }
      )
    },
    getOrderedSeancesGroups() {
      let groups = Array.from(this.seancesGroups.keys())
      return groups.sort(
        (a, b) => {
          if (a === b) {
            return 0
          }
          if (a < b) {
            return -1
          }
          return 1
        }
      )
    },
    getLinkedSeances(seance) {
      let linkedSeances = []
      for (let elt of this.seances) {
        if ((seance.date === elt.date) && (elt.id !== seance.id)) {
          for (let rule of this.rules) {
            if (
              (rule.seanceCodes.indexOf(seance.getCodeName()) >= 0) &&
              (rule.seanceCodes.indexOf(elt.getCodeName()) >= 0)
            ) {
              linkedSeances.push(elt)
            }
          }
        }
      }
      return linkedSeances
    },
    getChildrenSeances(seance) {
      return this.seances.filter(elt => seance.children.indexOf(elt.id) >= 0)
    },
    getParentSeance(seance) {
      if (seance.parent) {
        const parents = this.seances.filter(elt => elt.id === seance.parent)
        if (parents.length) {
          return parents[0]
        }
      }
      return null
    },
    getDependencySeances(seance) {
      return this.seances.filter(elt => seance.dependencies.indexOf(elt.id) >= 0)
    },
    getDailySeances(seance) {
      let dailySeances = []
      for (let elt of this.seances) {
        if ((seance.date === elt.date) && (elt.id !== seance.id)) {
          dailySeances.push(elt)
        }
      }
      return dailySeances
    },
    getRule(seance) {
      return this.getRuleFromCode(seance.getCodeName())
    },
    getRuleFromCode(code) {
      for (let rule of this.rules) {
        if (rule.match(code)) {
          return rule
        }
      }
      return null
    },
    getPrimarySeances(seance) {
      let primarySeances = []
      let seanceRule = this.getRule(seance)
      if (seanceRule && !seanceRule.isMain) {
        let seances = seanceRule.daily ? this.getDailySeances(seance) : this.seances
        for (let elt of seances) {
          let rule = this.getRule(elt)
          if (rule && rule.isMain) {
            primarySeances.push(elt)
          }
        }
      }
      return primarySeances
    },
    getSecondarySeances(seance) {
      let secondarySeances = []
      let seanceRule = this.getRule(seance)
      if (seanceRule && seanceRule.isMain) {
        let seances = seanceRule.daily ? this.getDailySeances(seance) : this.seances
        for (let elt of seances) {
          let rule = this.getRule(elt)
          if (rule && !rule.isMain) {
            secondarySeances.push(elt)
          }
        }
      }
      return secondarySeances
    },
    initializeRules() {
      for (let seance of this.seances) {
        let primarySeances = this.getPrimarySeances(seance)
        if (primarySeances.length) {
          for (let individual of this.getSeanceIndividuals(seance)) {
            let hasPrimarySelected = false
            for (let i = 0; (i < primarySeances.length) && !hasPrimarySelected; i++) {
              let primarySeance = primarySeances[i]
              if (primarySeance.doesIndividualHaveInscription(individual.id)) {
                hasPrimarySelected = true
              }
            }
            if (!hasPrimarySelected) {
              this.markInscriptionPossible(seance, individual, false)
            }
          }
        }
      }
    },
    markInscriptionPossible(seance, individual, value) {
      let key = '' + seance.id + '-' + individual.id
      this.inscriptionPossibilitiesMap.set(key, value)
    },
    getForbiddenReason(seance, individual) {
      if (seance.isIndividualInscriptionDoneByOther(individual.id)) {
        return 'Autre famille'
      }
      return seance.isIndividualInscriptionForbidden(individual.id)
    },
    isInscriptionPossible(seance, individual) {
      if (seance.parent) {
        return false
      }
      if (!seance.isAvailableForIndividual(individual.id)) {
        return false
      }
      if (seance.isIndividualInscriptionDoneByOther(individual.id)) {
        return false
      }
      if (seance.isIndividualInscriptionForbidden(individual.id) !== '') {
        return false
      }
      let key = '' + seance.id + '-' + individual.id
      if (this.inscriptionPossibilitiesMap.has(key)) {
        return this.inscriptionPossibilitiesMap.get(key)
      }
      return true
    },
    getForbiddenReason2(day, code, individual) {
      let seance = this.getSeanceFromCode(day, code)
      if (seance) {
        return this.getForbiddenReason(seance, individual)
      }
      return ''
    },
    isInscriptionPossible2(day, code, individual) {
      let seance = this.getSeanceFromCode(day, code)
      return this.isInscriptionPossible(seance, individual)
    },
    initializeRulesColors() {
      let primaryColors = ['#acdff8', '#ffa2a2', '#fbff8d', '#888']
      let secondaryColors = ['#ffe189', '#94ff94', '#ff9bff', '#ccc']
      let primaryCounter = 0
      let secondaryCounter = 0
      for (let rule of this.rules) {
        if (rule.isMain) {
          this.groupColors.set(rule.id, primaryColors[primaryCounter])
          primaryCounter += 1
          if (primaryCounter >= primaryColors.length) {
            primaryCounter = 0
          }
        } else {
          this.groupColors.set(rule.id, secondaryColors[secondaryCounter])
          secondaryCounter += 1
          if (secondaryCounter >= secondaryColors.length) {
            secondaryCounter = 0
          }
        }
      }
    },
    getSeanceColor(seance) {
      if (seance.fixedFee) {
        return '#bb96ff'
      }
      let rule = this.getRule(seance)
      if (rule) {
        if (this.groupColors.has(rule.id)) {
          return this.groupColors.get(rule.id)
        }
      }
      return 'transparent'
    },
    getCodeColor(code) {
      let rule = this.getRuleFromCode(code)
      if (rule) {
        if (this.groupColors.has(rule.id)) {
          return this.groupColors.get(rule.id)
        }
      }
      return 'transparent'
    },

    initializeSeanceCodes() {
      let allCodes = new Map()
      this.seancesFromCode.clear()
      for (let seance of this.seances) {
        let code = seance.getCodeName()
        let key = '' + seance.date + '-' + code
        this.seancesFromCode.set(key, seance)
        if (!allCodes.has(code)) {
          let rule = this.getRule(seance)
          allCodes.set(
            code, { code: code, rule: rule, seance: seance, label: seance.getBaseName(), }
          )
        }
      }
      const that = this
      this.allSeanceCodes = Array.from(allCodes.values()).sort(
        (elt1, elt2) => {
          const rule1 = elt1.rule
          const rule2 = elt2.rule
          const seance1 = elt1.seance
          const seance2 = elt2.seance
          // En 1er les codes qui ont une règle associée : primaire puis secondaire
          // en prenant en compte leur ordre
          // Sinon les séances classés ensuite par moment de la journée Matin, Repas, Après-Midi, Soir
          // puis par durée puis par ordre alpha du code
          if ((rule1 === null) && rule2) {
            return 1
          }
          if ((rule2 === null) && rule1) {
            return -1
          }
          if ((rule2 === rule1) || (rule2.id === rule1.id)) {
            const sortNumber1 = seance1.sortNumber()
            const sortNumber2 = seance2.sortNumber()
            if (sortNumber1 > sortNumber2) {
              return 1
            } else if (sortNumber1 < sortNumber2) {
              return -1
            } else {
              const code1 = elt1.code
              const code2 = elt2.code
              const duration1 = elt1.duration
              const duration2 = elt2.duration
              if (duration1 === duration2) {
                return (code1 === code2) ? 0 : ((code1 > code2) ? 1 : -1)
              } else {
                return duration1 > duration2 ? 1 : -1
              }
            }
          }
          if (rule1.order && rule2.order && (rule1.order !== rule2.order)) {
            return (rule1.order > rule2.order ? 1 : -1)
          } else {
            if (rule1.isMain && !rule2.isMain) {
              return -1
            }
            if (!rule1.isMain && rule2.isMain) {
              return 1
            }
            return (rule1.id > rule2.id) ? 1 : -1
          }
        }
      )
    },
    getAllIndividuals() {
      if (this.seances.length) {
        return this.seances.reduce(
          (accumulator, seance) => distinct(accumulator.concat(this.getSeanceIndividuals(seance))),
          []
        )
      }
      return []
    },
    getGridLines() {
      let gridLines = []
      for (let day of this.getFilteredDays()) {
        for (let individual of this.allIndividuals) {
          gridLines.push(
            {
              day: day,
              individual: individual,
              key: day + '-' + individual.id,
            }
          )
        }
      }
      return gridLines
    },
    getHeaderStyle(elt) {
      return getPeriodHeaderStyle(elt)
    },
    checkAll(code) {
      let isAllChecked = this.isAllChecked(code)
      let newValue = !isAllChecked
      for (let day of this.getFilteredDays()) {
        let seance = this.getSeanceFromCode(day, code)
        if (seance) {
          for (let individual of this.allIndividuals) {
            if (this.isInscriptionPossible(seance, individual)) {
              let inscription = seance.doesIndividualHaveInscription(individual.id)
              if (inscription !== newValue) {
                this.setIndividualInscription(seance, individual)
              }
            }
          }
        }
      }
    },
    isAllChecked(code) {
      let counter = 0
      for (let day of this.getFilteredDays()) {
        let seance = this.getSeanceFromCode(day, code)
        if (seance) {
          counter += 1
          for (let individual of this.allIndividuals) {
            if (this.isInscriptionPossible(seance, individual)) {
              if (!seance.doesIndividualHaveInscription(individual.id)) {
                return false
              }
            }
          }
        }
      }
      return counter > 0
    },
    onLimitsLoaded(event) {
      if (!this.hasLimits) {
        this.hasLimits = (
          (event.groupLimits.length > 0) ||
          (event.dayLimits.length > 0) ||
          (event.seanceGroupLimits.length > 0) ||
          (event.seanceLimits.length > 0) ||
          (event.excursionGroupLimits.length > 0) ||
          (event.excursionLimits.length > 0)
        )
      }
      this.$emit('limits-loaded', event)
    },
    weekNum(date) {
      return 'Semaine ' + moment(date).isoWeek()
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .inline {
    display: inline-block;
    margin-left: 3px;
  }
  .table tr:first-of-type th, .table tr:first-of-type td {
    border-top: none
  }
  .table th, .table td {
    border-top-color: #aaa;
  }
  .table tr.line-day th, .table tr.line-day td {
    border-top-color: #222;
  }
  td.grid-col {
    text-align: center;
  }
  .group-header {
    background: #444;
    color: #fff;
    padding: 2px 5px;
    margin-top: 10px;
  }
  .table tr.tr-header th {
    color: #888;
    background: #ccc;
  }
  .checkbox-holder {
    position: relative;
    display: inline-block;
  }
  input[type="checkbox"] {
    position: absolute;
    opacity: 0.01;
    top: 4px;
    left: 2px;
  }
  input[type="checkbox"] + span:before {
    font: 14pt 'Font Awesome 6 Free';
    content: '\f096';
    display: inline-block;
    width: 14pt;
    padding: 2px 0 0 3px;
    margin-right: 0.5em;
  }
  input[type="checkbox"]:checked + span:before {
    content: '\00f046';
  }
  input[type="checkbox"]:focus + span:before {
    outline: 1px dotted #aaa;
  }
  input[type="checkbox"]:disabled + span {
    color: #aaa;
    cursor: not-allowed;
  }
  .confirmation-warning {
    background: #f2f2a2;
    padding: 10px;
    font-size: 1.2em;
  }
  .seance-code {
    font-size: 0.8em;
  }
  .grid-col {
    text-align: center;
  }
  .seance-comments {
    font-size: 10px;
  }
  .multiple-holder {
    margin: 0 auto;
    width: 52px;
    text-align: center;
  }
  .checkbox-holder .fa-ban, .multiple-holder .fa-ban {
    color: #aaa;
    width: 14pt;
    font-size: 14pt;
    padding: 2px 0 0 3px;
    display: inline-block;
    margin-right: 0.5em;
  }
</style>
